<script lang="ts" setup>
import {
  CACHE_TTL_S_1_HOUR,
  CACHE_TTL_S_12_HOURS,
  CACHE_TTL_S_15_MIN,
  getProductsTag,
  HOMEPAGE_PAGE_TAG,
} from '~/lib/cache-utils';
import { filterMaybeList, getProductIdListFromCmsZone } from '~/lib/strapi-utils';
import GetHomepageGQL from '~/queries/homepage.gql';
import { type GetHomepagesQuery, type GetHomepagesQueryVariables } from '~/types/graphql';

const { publicationState } = useCmsPreviewMode();

const graphqlKey = 'page:homepage:ggl';

const queryVariables = computed<GetHomepagesQueryVariables>(() => ({
  publicationState: publicationState.value,
}));

const { data: homepage, error } = await useCachedAsyncQuery<GetHomepagesQuery>(
  graphqlKey,
  GetHomepageGQL,
  queryVariables,
  {
    deep: false,
    serverCacheTags: (data) => {
      return [
        HOMEPAGE_PAGE_TAG,
        ...getProductsTag(getProductIdListFromCmsZone(data.homepage?.data?.attributes?.main as CmsDynamicZoneBlock[])),
      ];
    },
    serverMaxAge: CACHE_TTL_S_12_HOURS,
    clientMaxAge: CACHE_TTL_S_15_MIN,
  },
);

const data = computed(() => {
  return homepage.value?.homepage?.data?.attributes;
});

const filteredBlocks = computed<CmsDynamicZoneBlock[]>(() => {
  if (data.value?.main) {
    return filterMaybeList(data.value.main) as CmsDynamicZoneBlock[];
  }

  return [];
});

const productIds = getProductIdListFromCmsZone(filteredBlocks.value);

const productTags = getProductsTag(productIds);

if (error.value) {
  throw createError({
    message: `Fail to load homepage data (${error.value.toString()})`,
    statusCode: 500,
    cause: error.value,
  });
}

useCmsMetaTags(data.value?.seo);

const { trackPageView } = useTracking();

trackPageView(data.value?.seo.metaTitle ?? 'homepage');
</script>

<template>
  <div class="homepage">
    <RenderCacheable
      :cache-tags="[HOMEPAGE_PAGE_TAG, ...productTags]"
      :max-age="CACHE_TTL_S_1_HOUR"
      :async-data-keys="[`findProductById:${productIds.join('+')}`]"
    >
      <CmsBlockLandingHero v-if="data?.hero" :data="data.hero" />

      <CmsDynamicZone v-if="filteredBlocks.length > 0" :blocks="filteredBlocks" />
    </RenderCacheable>
  </div>
</template>
